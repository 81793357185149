import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel,Checkbox,Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";


import AddNewVehicle from "./AddNewVehicle"
import { Api, RemoveRedEye } from "@mui/icons-material";

const API_URL = `https://dashapi.mivanrental.com/`


const VehicleGQLSchema = `
id
MainInfo{
    Title
    Slug
    Description
    Brand{
        id
        Name
        Logo
    }
    Model
}
YoutubeLink
MainImage{
    FilePath
}
Galleries
Features{
    Transmission
    Fuel
    BodyType
    Passengers
    AirConditioning
    Stereo
    Luggage
    PowerSteering
    Doors
    InteriorMaterial
    HDTV
    PowerWindows
    RemoteKeyLessEntry
    BackUpCam
    DVD
}
isAvailable
`

const Vehicle = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [vehiclesCount,setVehiclesCount] = useState('')
    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewVehicle,setIsNewVehicle] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [vehicles,setVehicles] = useState([])
    const [selectedVehicle,setSelectedVehicle] = useState(null)

    const HandleMainInfoSubmit = (title,description,brand,model,filepath,imgs,transmission,fuel,bodytype,passengers,airconditioning,stereo,luggage,powersteering,doors,interiormaterial,hdtb,powerwindows,remotekey,backupcam,dvd,youtubeLink) =>{
        isNewVehicle ? AddNewVehicleFunc(title,description,brand,model,filepath,imgs,transmission,fuel,bodytype,passengers,airconditioning,stereo,luggage,powersteering,doors,interiormaterial,hdtb,powerwindows,remotekey,backupcam,dvd,youtubeLink) : EditVehicleFunc(title,description,brand,model,filepath,imgs,transmission,fuel,bodytype,passengers,airconditioning,stereo,luggage,powersteering,doors,interiormaterial,hdtb,powerwindows,remotekey,backupcam,dvd,youtubeLink)
    }

   
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    



   
    const ModalComponents = {
        "ADD": <AddNewVehicle NewVehicle={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewVehicle NewVehicle={false} Vehicle={selectedVehicle} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>     
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewVehicle(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleEditClick = (item) =>{
        setSelectedVehicle(item)
        setModalStatus("EDIT")
        setIsNewVehicle(false)
        setModalOpen(true)
    }
    

  
    
    const handleDeleteClick = async(item) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteVehicle(id:"${item.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }


    const editVehiclesCount = async () =>{
      try{
        const res = await axios.post(API_URL,{
          query:`mutation{
            ChangeVehicleCount(val:"${vehiclesCount}"){
              Key
              Value
            }
          }`
        }, {
          headers: {
            token: `Bearer ${getAuthentication()}`
          }
        })

        if(res.data.data.ChangeVehicleCount){
          console.log('DONE')
        }
      } 
      catch(err){
        console.log(err)
      }
    }
 
   const fetchVehiclesCount = async() =>{
      try{
        const res = await axios.post(API_URL,{
          query:`query{
            GetVehicleCount{
              Key
              Value
            }
          }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })

        if(res.status === 200){
          setVehiclesCount(res.data.data.GetVehicleCount.Value)
        }
      }
      catch(err){

      }
   }
    
    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
          const res = await axios.post(API_URL,{
            query:`query{
                GetAllVehicles{
                ${VehicleGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setVehicles(res.data.data.GetAllVehicles)
          setLoaderVisibility('none')
        }
      }
      catch(err){}
    }


    const ChangeAvaibility = async(vehicle) => {
        const res = await axios.post(API_URL,{
          query:`
            mutation{
              ToggleAvailabityVehicle(id:"${vehicle.id}",available:${!vehicle.isAvailable}){
                ${VehicleGQLSchema}

              }
            }
          `
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        fetchData()

    }

  

    const AddNewVehicleFunc = async(title,description,brand,model,filepath,imgs,transmission,fuel,bodytype,passengers,airconditioning,stereo,luggage,powersteering,doors,interiormaterial,hdtb,powerwindows,remotekey,backupcam,dvd,youtubelink) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewVehicle(title:"${title}",description:"${description}",brand:"${brand}",model:"${model}",filepath:"${filepath}",imgs:"${imgs}",transmission:"${transmission}",fuel:"${fuel}",bodytype:"${bodytype}",passengers:${passengers},airconditioning:${airconditioning},stereo:${stereo},luggage:${luggage},powersteering:${powersteering},doors:${doors},interiormaterial:"${interiormaterial}",hdtb:${hdtb},powerwindows:${powerwindows},remotekey:${remotekey},backupcam:${backupcam},dvd:${dvd},youtube:"${youtubelink}") {
                  ${VehicleGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewVehicle.id !== null){
            fetchData()
          }
    }


   

    const EditVehicleFunc = async(title,description,brand,model,filepath,imgs,transmission,fuel,bodytype,passengers,airconditioning,stereo,luggage,powersteering,doors,interiormaterial,hdtb,powerwindows,remotekey,backupcam,dvd,youtubelink) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditVehicle(id:"${selectedVehicle.id}",title:"${title}",description:"${description}",brand:"${brand}",model:"${model}",filepath:"${filepath}",imgs:"${imgs}",transmission:"${transmission}",fuel:"${fuel}",bodytype:"${bodytype}",passengers:${passengers},airconditioning:${airconditioning},stereo:${stereo},luggage:${luggage},powersteering:${powersteering},doors:${doors},interiormaterial:"${interiormaterial}",hdtb:${hdtb},powerwindows:${powerwindows},remotekey:${remotekey},backupcam:${backupcam},dvd:${dvd},,youtube:"${youtubelink}"){
                        ${VehicleGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditVehicle.id !== null){
            fetchData()
        }
    }

    useEffect(()=>{
      fetchVehiclesCount()
    },[])

    useEffect(() => {
        fetchData()

        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Vehicles Count`} subtitle={`Modify Vehicles Count`} />
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
              <TextField variant="filled"
                    type="text"
                    placeholder="Vehicles Count"
                    color="secondary"
                    value={vehiclesCount}
                    onChange={(e)=>setVehiclesCount(e.target.value)}
                    sx={{
                      width:"80%"
                    }}></TextField>
              <Button variant="contained" onClick={editVehiclesCount} color="secondary">Edit Vehicles Count</Button>

            </Box>
            <Header title={`Vehicles`} subtitle={`Add, modify and delete vehicles`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                  
                  
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Vehicle</Button>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
      <TableCell>Vehicle Name</TableCell>
      <TableCell>Brand & Model</TableCell>
      <TableCell>Preview</TableCell>
      <TableCell>Enabled</TableCell>
      <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    vehicles.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(lng => (
        <TableRow key={lng.id}>
        <TableCell>{lng.MainInfo.Title}</TableCell>
        <TableCell>{lng.MainInfo.Model} - {lng.MainInfo.Brand.Name}</TableCell>
        <TableCell><a target="_blank" href={"https://mivanrental.com/Vehicle/"+lng.MainInfo.Slug}>
            <IconButton color="secoondary" >
                <RemoveRedEye />
            </IconButton>
            </a></TableCell>

        <TableCell><Switch color="secondary"  checked={lng.isAvailable} onChange={()=>ChangeAvaibility(lng)} /></TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(lng)}> 
                <EditIcon />
            </IconButton>
           
            <IconButton color="error" onClick={() => handleDeleteClick(lng)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={vehicles.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
        </Box>
    )
}

export default Vehicle