import { Box, IconButton, Typography,Button, useTheme ,TextField,Snackbar,Alert } from "@mui/material"
import { display, width } from "@mui/system"
import { useState } from "react"
import { tokens } from "../../../theme"
import axios from "axios"
import mivanrental from './../../../mivanrentallogo.png'
import { setAuthentication } from "../../../auth"
const AUTH_URL = `https://auth.mivanrental.com/`

const SignIn = ({signInHandler}) =>{

    const [username,SetUsername] = useState('')
    const [password,SetPassword] = useState('')
    const [isError,SetIsError] = useState(false)

    
    const HandleSignInBTN = async() =>{

        try {

        await axios.post(AUTH_URL,{
            email:username,
            password:password
        })
        .then(res => {

            if(res.status  === 200){
                setAuthentication(res.data.accessToken)
                signInHandler()
            }
            else{
                SetIsError(true)
            }
        })
    }
    catch(err){
        SetIsError(true)
    }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        SetIsError(false);
      };

    return (
      <Box sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-around",
        width:"100",

      }}>
        <Box sx={{ display: 'flex',width:"50vw", justifyContent: 'center', alignItems: 'center' }}>
            <img src={mivanrental} alt="My Image" style={{ width: '40%' }} />
        </Box>
        <Box sx={{
            width:"40vw",
            p:10,
            display:"flex",
            justifyContent:"center", alignItems: 'flex-start',flexDirection:'column'
        }}>
             <Typography color="secondary" sx={{
                mb:3
             }} variant='h2'>
                Welcome To Mivanrental Dashboard
            </Typography>
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Username | Email ..."
                onChange={(e)=>SetUsername(e.target.value)}
                color="secondary"
                name="address1"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password ..."
                onChange={(e)=>SetPassword(e.target.value)}
                color="secondary"
                name="address1"
                sx={{ mt:4,mb:3 }}
              />
            <Button sx={{ fontWeight: 'bold' }} type="submit" color="secondary" onClick={HandleSignInBTN} variant="contained">LOGIN</Button>

        </Box>
        <Snackbar
            open={isError}
            autoHideDuration={3000}
            message="Note archived"
            onClose={handleClose}>
            <Alert severity="error">Username or Password is Incorrect !</Alert>
        </Snackbar>    
        
    </Box>
    )
}
export default SignIn
