import { Box, Button, TextField,useTheme,Typography,IconButton } from "@mui/material";
import { Formik } from "formik";
import { tokens } from "./../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import {  useState } from "react";
import CloseIcon from '@mui/icons-material/Close';


const AddNewFAQ = ({NewItem,Item,HandleMainInfoSubmit,closingModal}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [question,setQuestion] = useState(NewItem?'':Item.Question)
  const [answer,setAnswer] = useState(NewItem?'':Item.Answer)
  const [rank,setRank] = useState(NewItem? 0: Item.Rank)
  
  const HandleSubmitBtn = () =>{ 
    HandleMainInfoSubmit(question,answer,rank)
    closingModal()
}

 



  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"60%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewItem ? "Add New Item" : "Edit Item"}</Typography>
      {!NewItem ?(<Typography variant="h6" sx={{
        textAlign:"center"
      }}>{Item.Text}</Typography>):(<></>) }
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
            display:'flex',
            flexDirection:"column",

            justifyContent:'space-around'
        }}>
                <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    value={question}
                    fullWidth
                    onChange={(e)=>setQuestion(e.target.value)}
                    label="Question"
                    color="secondary"
                    />
                    <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    value={answer}
                    fullWidth
                    multiline
                    rows="3"
                    onChange={(e)=>setAnswer(e.target.value)}
                    label="Answer"
                    color="secondary"
                    />
                    <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="number"
                    value={rank}
                    fullWidth
                    onChange={(e)=>setRank(e.target.value)}
                    label="Item"
                    color="secondary"
                    />
                 
        </Box>
  
        
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewItem? `Add New Item` : `Edit Item`}</Button>

        </Box>
          
            
              

        </form>
      )}
   
      </Formik>
    
    </Box>
  );
};
export default AddNewFAQ;