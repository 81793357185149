import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import formatDateUS from "../../../FormatDate";




const PreviewMod = ({Request,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)



  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{Request.MainInfo.FullName} | {Request.Vehicle.MainInfo.Brand.Name + ' - ' + Request.Vehicle.MainInfo.Model}</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
          display:"flex",
          justifyContent:"space-around"
        }}>
            <Box sx={{
                width:'50%',
                paddingTop:4
            }}>
                <img style={{
                    width:"100%"
                }} src={`https://cloud.mivanrental.com/${Request.Vehicle.MainImage.FilePath}`}/>
                <h1 style={{textAlign:"center"}}>
                {Request.Vehicle.MainInfo.Brand.Name + ' - ' + Request.Vehicle.MainInfo.Model}
                </h1>
            </Box>
            <Box sx={{
                width:"50%"
            }}>
                <ul style={{
                    fontSize:"22px",
                    listStyle:"none"
                }}>
                    <li>
                        <strong>Name</strong>
                        <span style={{
                            color:"white",
                            padding:"0 10px"
                        }}>{Request.MainInfo.FullName}</span>
                    </li>
                    <li>
                        <strong>Email</strong>
                        <a style={{
                            color:"white",
                            padding:"0 10px"
                        }} href={`mailto:${Request.MainInfo.Email}`}>{Request.MainInfo.Email}</a>
                    </li>
                    <li>
                        <strong>Phone Number</strong>
                        <span style={{
                            color:"white",
                            padding:"0 10px"
                        }}><a style={{
                            color:"white",
                            padding:"0 10px"
                        }} href={`tel:${Request.MainInfo.Telephone}`}>{Request.MainInfo.Telephone}</a></span>
                    </li>
                    <li>
                        <strong>From</strong>
                        <span style={{
                            color:"white",
                            padding:"0 10px"
                        }}>{formatDateUS(new Date(Request.Duration.From))}</span>
                    </li>
                    <li>
                        <strong>To</strong>
                        <span style={{
                            color:"white",
                            padding:"0 10px"
                        }}>{formatDateUS(new Date(Request.Duration.To))}</span>
                    </li>
                    {
                       ( Request.Documents.Insurance !== 'null' && Request.Documents.Insurance !== null) && (
                        <li>
                             <Button sx={{mt:2}} variant="contained" color="secondary" href={`https://cloud.mivanrental.com/${Request.Documents.Insurance}`} target="_blank">Insurance</Button>

                        </li>
                       )
                    }
                    {
                       ( Request.Documents.DrivingLiscence !== 'null' && Request.Documents.DrivingLiscence !== null) && (
                        <li>
                             <Button sx={{mt:2}} variant="contained" color="secondary" href={`https://cloud.mivanrental.com/${Request.Documents.DrivingLiscence}`} target="_blank">Driving License</Button>

                        </li>
                       )
                    }
                </ul>
            </Box>
        </Box>
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default PreviewMod;