import { Box,useTheme,TableContainer,Table,TableBody,TableHead,TableCell,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"
import Header from "../../../components/Header"
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import { useEffect, useState } from "react"
import { tokens } from "../../../theme";
import axios from "axios";
import { getAuthentication } from "../../../auth";
import formatDateUS from "../../../FormatDate";
import { PreviewRounded } from "@mui/icons-material";
import PreviewMod from "./PreviewAir";
const API_URL = `https://dashapi.mivanrental.com/`


const RequestSchemaGQL = `
id
FullName
Email
PhoneNumber
Message
CreatedAt
Seen
`

const Airport = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)


    const [requests,setRequests] = useState([])
    const [selectedRequest,setSelectedRequest] = useState(null)


    const [modalStatus,setModalStatus] = useState('VIEW')
    const [modalOpen,setModalOpen] = useState(false)

    const handleCloseModal = () =>{
        setModalOpen(false)
    }

    const ModalComponents ={
        "VIEW": <PreviewMod  closingModal={handleCloseModal} Request={selectedRequest} />
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }


    const PreviewRequest = (request) =>{
        setSelectedRequest(request)
        setModalOpen(true)
    }
    const handleDeleteClick = async(item) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteAirport(id:"${item.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }


    const fetchData = async() => {
        setLoaderVisibility('flex')
        try{
            const res = await axios.post(API_URL,{
                query:`query{
                    GetAirport(limit:${rowsPerPage},offset:${page}){
                    ${RequestSchemaGQL}
                  }
                }`
            },{
              headers:{
                token:`Bearer ${getAuthentication()}`
              }
            })
            if(res.status === 200){
                setRequests(res.data.data.GetAirport)
                setLoaderVisibility('none')
            }

        }
        catch(err){

        }
    }

    useEffect(()=>{
        fetchData()
    },[])

    return (
        <Box sx={{
            m:"20px"
        }}>
            <Header title={`Airport Pickup Request`} subtitle={`View Reuqests`}/>
            <Modal open={modalOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <>
                        {ModalComponents[modalStatus]}
                    </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                  
                  
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>
            
            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>From</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    requests.map(lng => (
        <TableRow key={lng.id}>
            <TableCell>{lng.FullName}</TableCell>
            <TableCell>{formatDateUS(new Date(lng.CreatedAt))}</TableCell>
            
            <TableCell>
                <IconButton color="secondary" onClick={()=>PreviewRequest(lng)}>
                    <PreviewRounded />
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteClick(lng)}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={requests.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>

        </Box>
    )
}

export default Airport