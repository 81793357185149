import { Box,IconButton,useTheme } from "@mui/material"
import { useContext } from "react"
import { colorModeContext,tokens } from "../../theme"
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import LogoutIcon from '@mui/icons-material/Logout';
import { removeAuthentication } from "../../auth";
const TopBar = ({signOutHandler}) =>{
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(colorModeContext)

    const HandleSignOut = () =>{
        removeAuthentication()
        signOutHandler()
    }
    return(
        <Box display="flex" justifyContent="flex-end"  p={2}>
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {
                        theme.palette.mode === 'dark' ? (<LightModeOutlinedIcon />) : (<DarkModeOutlinedIcon />)
                    }
                </IconButton>
               
              
                <IconButton>
                    <PersonOutlinedIcon />
                </IconButton>
                <IconButton  onClick={HandleSignOut}>
                    <LogoutIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TopBar