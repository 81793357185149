import React from "react";

import { Routes, Route } from "react-router-dom";
import TopBar from "./../../global/Topbar";
import SideBar from "./../../global/SideBar";
import Carousels from "../../MivanRental/Carousel";
import ChooseUs from "../../MivanRental/ChooseUs";
import FAQ from "../../MivanRental/Faq";
import Brand from "../../MivanRental/Brands";
import Vehicle from "../../MivanRental/Vehicles";
import Request from "../../MivanRental/Requests";
import Inbox from "../../MivanRental/Inbox";
import Airport from "../../MivanRental/Airport";
import Shuttle from "../../MivanRental/Shuttle";

const Main = ({ signOutHandler }) => {


  return (
    <>
      <SideBar  />
      <main className="content">
        <TopBar signOutHandler={signOutHandler} />
        
        <Routes>
        <Route path="/carousel" element={<Carousels key="c1" />} />          
        <Route path="/chooseus" element={<ChooseUs key="c2" />} />          
        <Route path="/faq" element={<FAQ key="c3" />} />    
        <Route path="/brands" element={<Brand key="c4" />} />    
        <Route path="/vehicles" element={<Vehicle key="c5" />} />    
        <Route path="/requests" element={<Request key="c6" />} />    
        <Route path="/inbox" element={<Inbox key="c7" />} />    
        <Route path="/airPortPickUp" element={<Airport key="c8" />} />    
        <Route path="/shuttleBus" element={<Shuttle key="c9" />} />    

        </Routes>
      </main>
    </>
  );
};

export default Main;
