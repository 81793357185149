import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import formatDateUS from "../../../FormatDate";




const PrviewInbox = ({Request,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)



  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height:"50%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{Request.FullName} </Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
          display:"flex",
          justifyContent:"space-start"
        }}>
           
                <ul style={{
                    fontSize:"16px",
                    listStyle:"none",
                    
                }}>
                    <li>
                        <strong>Name</strong>
                        <span style={{
                            color:"white",
                            padding:"0 10px"
                        }}>{Request.FullName}</span>
                    </li>
                    <li>
                        <strong>Email</strong>
                        <a style={{
                            color:"white",
                            padding:"0 10px"
                        }} href={`mailto:${Request.Email}`}>{Request.Email}</a>
                    </li>
                    <li>
                        <strong>Phone Number</strong>
                        <span style={{
                            color:"white",
                            padding:"0 10px"
                        }}><a style={{
                            color:"white",
                            padding:"0 10px"
                        }} href={`tel:${Request.PhoneNumber}`}>{Request.PhoneNumber}</a></span>
                    </li>
                  
                    
                    <li>
                        <strong>Message</strong>
                        <p>
                            {Request.Message}
                        </p>
                    </li>
                   
                </ul>
        </Box>
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default PrviewInbox; 