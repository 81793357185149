import { Box,useTheme,Pagination,Typography,TableContainer,Table,TableBody,TableHead,TableCell,TextField,TableRow,TablePagination,Button,IconButton,Modal,Select,MenuItem,FormControl,InputLabel,Checkbox,Switch } from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";


import AddNewCarousel from "./AddNewCarousel"
import { Preview, PreviewOutlined, PreviewRounded, RemoveRedEye } from "@mui/icons-material";


const API_URL = `https://dashapi.mivanrental.com/`


const CarouselGQLSchema = `
id
FilePath
Alt
`

const Carousels = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewCountry,setIsNewCountry] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [countriesCount,setCountriesCount] = useState(0)

    
    const [carousels,setCarousels] = useState([])
    const HandleMainInfoSubmit = (alt,filepath) =>{
        AddNewCarouselFunc(alt,filepath)
    }

   

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    
    const EditModalConfigStatus = (state) =>{
      setModalStatus(state)
    }

   
    const ModalComponents = {
        "ADD": <AddNewCarousel  HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
      
        
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewCountry(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

 
    
 
    
    const handleDeleteClick = async(carousel) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteCarousel(id:"${carousel.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

 

    
    
    const fetchData = async () => {

        setLoaderVisibility('flex')
  
        try{
     
       const res = await axios.post(API_URL,{
            query:`query{
                GetAllCarousels{
                ${CarouselGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setCarousels(res.data.data.GetAllCarousels)
          setLoaderVisibility('none')
        }
      }
      catch(err){
  
      }
    }




    const AddNewCarouselFunc = async(alt,filepath) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewCarousel(filepath:"${filepath}",alt:"${alt}") {
                  ${CarouselGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewCarousel.id !== null){
            fetchData()
          }
    }




   

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Carousel`} subtitle={`Add and delete carousels`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                    
                  
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Carousel</Button>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Description</TableCell>
        <TableCell>Preview</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    carousels.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(lng => (
        <TableRow key={lng.id}>
        <TableCell>{lng.Alt}</TableCell>
        <TableCell><a target="_blank" href={"https://cloud.mivanrental.com/"+lng.FilePath}>
            <IconButton color="secoondary" >
                <RemoveRedEye />
            </IconButton>
            </a></TableCell>
        <TableCell>
            <IconButton color="error" onClick={() => handleDeleteClick(lng)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={carousels.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
        </Box>
    )
}

export default Carousels