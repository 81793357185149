import { Box, Button, TextField,useTheme,Typography,TextareaAutosize,Select, List, ListItem, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,IconButton,Switch ,Divider} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../../theme";
import "@yaireo/tagify/dist/tagify.css"
import { useRef, useState,useEffect } from "react";
import { getAuthentication } from "../../../auth";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
const API_URL = `https://dashapi.mivanrental.com/`
const UPLOAD_URL = `https://upload.mivanrental.com/`

const BrandGQLSchema = `
id
Name
Logo
`

const AddNewVehicle = ({NewVehicle,Vehicle,HandleMainInfoSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [allBrands,setAllBrands] = useState([])
  const [allFuels,setAllFuels] = useState([
    "Gasoline",
    "Diesel",
    "Electric",
    "Hybrid",
    "Natural Gas",
    "Propane",
    "Hydrogen",
    "Biodiesel",
    "Flex Fuel (E85)",
    "CNG (Compressed Natural Gas)",
    "LPG (Liquefied Petroleum Gas)",
    "Methanol",
    "Ethanol (E10)",
    "Plug-In Hybrid",
    "Synthetic Fuels"
  ])
  const [allTransmissions,setAllTransmission] = useState([
      "Manual (MT)",
      "Automatic (AT)",
      "Continuously Variable (CVT)",
      "Dual-Clutch (DCT)",
      "Semi-Automatic",
      "Tiptronic",
      "Direct-Shift Gearbox (DSG)",
      "Automated Manual (AMT)",
      "Hydrostatic",
      "Infinitely Variable (IVT)",
      "Electric Vehicle (EV)",
      "Hydrogen Fuel Cell Vehicle",
      "Torque Converter",
      "Manual Automated (MAT)"
  ])
  const [allBodyTypes,setAllBodyTypes] = useState([
    "Hatchback",
    "SUV",
    "Minivan",
    "VAN",
    "Roadster",
    "Hardtop",
    "Sedan",
    "Convertible",
    "Crossover",
    "Compact",
    "Truck",
    "Coupé de ville",
    "Coupe SUV",
    "Brougham",
    "Car"
  ])
  const [title,setTitle] = useState(NewVehicle?'':Vehicle.MainInfo.Title)
  const [description,setDescription] = useState(NewVehicle? '' : Vehicle.MainInfo.Description)
  const [selectedBrand,setSelectedBrand] = useState(NewVehicle? null : Vehicle.MainInfo.Brand.id)
  const [model,setModel] = useState(NewVehicle?'':Vehicle.MainInfo.Model)

  const [mainImage,setMainImage] = useState(NewVehicle?'':Vehicle.MainImage.FilePath)
  const [imgs,setImgs] = useState(NewVehicle?[]:Vehicle.Galleries)

  const [transmission,setTransimission] = useState(NewVehicle?'Automatic (AT)':Vehicle.Features.Transmission)
  const [fuel,setFuel] = useState(NewVehicle?'Gasoline':Vehicle.Features.Fuel)
  const [bodyType,setBodyType] = useState(NewVehicle?'VAN':Vehicle.Features.BodyType)
  const [passengers,setPassengers] = useState(NewVehicle?0:Vehicle.Features.Passengers)
  const [airConditioning,setAirConditioning] = useState(NewVehicle?false:Vehicle.Features.AirConditioning)
  const [powerSteering,setPowerSteering] = useState(NewVehicle?false  :Vehicle.Features.PowerSteering)
  const [doors,setDoors] = useState(NewVehicle?0:Vehicle.Features.Doors)
  const [stereo,setStereo] = useState(NewVehicle?false:Vehicle.Features.Stereo)
  const [luggage,setLuggage] = useState(NewVehicle?0:Vehicle.Features.Luggage)
  const [powerWindows,setPowerWindows] = useState(NewVehicle?false:Vehicle.Features.PowerWindows)
  const [remoteKeyLessEntry,setRemoteKeyLessEntry] = useState(NewVehicle?false:Vehicle.Features.RemoteKeyLessEntry)
  const [backUpCam,setBackUpCam] = useState(NewVehicle?false:Vehicle.Features.BackUpCam)
  const [DVD,setDVD] = useState(NewVehicle?false:Vehicle.Features.DVD)
  const [HDTV,setHDTV] = useState(NewVehicle?false:Vehicle.Features.HDTV)
  const [interiorMaterial,setInteriorMaterial] = useState(NewVehicle?'':Vehicle.Features.InteriorMaterial)
  const [youtubeLink,setYoutubeLink] = useState(NewVehicle?'':Vehicle.YoutubeLink)

  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedMainFile, setSelectedMainFile] = useState(null)
  const [msg,setMSG] = useState('')

  const HandleSubmitBtn = () =>{
      HandleMainInfoSubmit(title,description,selectedBrand,model,mainImage,imgs,transmission,fuel,bodyType,passengers,airConditioning,stereo,luggage,powerSteering,doors,interiorMaterial,HDTV,powerWindows,remoteKeyLessEntry,backUpCam,DVD,youtubeLink)
      closingModal()
  }
  
  const UploadFileMain = async() =>{
    const formData = new FormData();
    formData.append('image', selectedMainFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setMainImage(res.data.name)
    setMSG('File has uploaded successfully')

   }
  const UploadFile = async() =>{
    const formData = new FormData();
    formData.append('image', selectedFile);
    const res = await  axios.post(UPLOAD_URL, formData, {
      headers: {
        token:`Bearer ${getAuthentication()}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    setImgs([...imgs, res.data.name])
    setMSG('File has uploaded successfully')

   }
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setMSG('File has been selected')
  }


  

  const handleMainFileChange  = (event) => {
    setSelectedMainFile(event.target.files[0])
  }



 

  
  const fetchData = async () => {
    try{
      const res = await axios.post(API_URL,{
            query:`query{
              GetCarBrands{
                ${BrandGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
          setAllBrands(res.data.data.GetCarBrands);
        }
  }
  catch(err){

  }
}

useEffect(() => {
    fetchData()
    }, [])

  return (
    <Box sx={{
        position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  height:"95%",
  bgcolor: colors.primary[400],
  overflowY:'scroll',
  boxShadow: 24,
  p: 4,
    }}>
      <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
      
      
      <Typography variant="h3" color="secondary" sx={{
        textAlign:"center"
      }}>{NewVehicle ? "Add New Vehicle" : "Edit Vehicle"}</Typography>
   
      <Formik
       
> 
{({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box sx={{
          display:"flex",
          justifyContent:"space-around"
        }}>
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-around',
            width:"49%"
        }}>
                <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e)=>setTitle(e.target.value)}
                    label="Title ..."
                    color="secondary"
                    />
                    <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={description}
                    multiline
                    rows="4"
                    onChange={(e)=>setDescription(e.target.value)}
                    label="Description ..."
                    color="secondary"
                    />
                <Box sx={{mt:2,
                  display:"flex",
                  justifyContent:"space-around"
                }}>
                  <Box sx={{display:'flex',flexDirection:"column",width:"48%"}}>
                  <InputLabel color="secondary" id="demo-simple-select-label414">Select Brand ...</InputLabel>

<Select sx={{
 width:"100%"
}} variant="filled"  value={selectedBrand} onChange={(e)=>setSelectedBrand(e.target.value)}   labelId="demo-simple-select-label414"  color="secondary" fullWidth label="Select Brand ..." >
   {allBrands.map((cat) => (
                 <MenuItem key={cat.id} value={cat.id}>
                   {cat.Name}
                 </MenuItem>
             ))}
   </Select>
                  </Box>
                          
                        <TextField 
                    sx={{
                        mt:3,
                        width:"48%"
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={model}
                    onChange={(e)=>setModel(e.target.value)}
                    label="Model ..."
                    color="secondary"
                    />

                </Box>

                <Box>
                <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={youtubeLink}
                    onChange={(e)=>setYoutubeLink(e.target.value)}
                    label="Youtube Link (Embed Link) ..."
                    color="secondary"
                    />
                </Box>

                <Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Main Image <input type="file" accept="image/*" onChange={handleMainFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFileMain} >Upload</Button>
        </Box>
              

                <Box sx={{
                  mt:2,
                  height:"30vh",
                  overflowY:"scroll",
                  border:1,
                  borderColor:"#aaa",
                  p:1
                }}>

<Box sx={{
                mt:1,
                display:'flex',
                justifyContent:'space-around'
            }}>
            <Button sx={{mt:1,width:"70%"}}  color='secondary' variant="outlined" component="label"> Browse Other Image <input type="file" accept="image/*" onChange={handleFileChange} hidden/></Button>
            <Button sx={{mt:1,width:"22%"}}  color='secondary' variant="contained" component="label" onClick={UploadFile} >Upload</Button>

        </Box>
        <Typography sx={{textAlign:'center',mt:1,mb:1}} color="error">{msg}</Typography>
        <Divider />
                  <Box sx={{
                    display:"flex",
                    flexDirection:"column",
                    px:4
                  }}>

                    {
                      imgs.map(img=>(
                        <>
                        <Box sx={{
                          mt:2,
                          display:"flex",
                          justifyContent:"space-around"
                        }}>
                            <Typography variant="p">{img}</Typography>
                            <Box>
                            <IconButton color="secondary" onClick={() =>window.open('https://cloud.mivanrental.com/'+img, '_blank') }> 
                              <RemoveRedEyeIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() =>setImgs(imgs.filter((image) => image !== img)) }> 
                              <DeleteIcon />
                            </IconButton>

                              </Box>
                          </Box>
                          <Divider />
                          </>
                      ))
                    }

                  </Box>

                
                </Box>
                <Typography>{imgs.length} has been uploaded</Typography>


                    
        </Box>

        <Box sx={{
          width:'45%',
          mt:3,
          display:"flex",
          flexDirection:"column",
          justifyContent:"flex-start"
        }}>
          <Typography variant="h5" sx={{
            textAlign:"left"
          }}>Features</Typography>
          <Box sx={{
            display:"flex",
            justifyContent:"space-around",
            mt:3
          }}>
              <Box sx={{width:"30%"}}>

<InputLabel color="secondary" id="demo-simple-select-label414">Transmission Type</InputLabel>

<Select sx={{
width:"100%"
}} variant="filled"  value={transmission} onChange={(e)=>setTransimission(e.target.value)}   labelId="demo-simple-select-label414"  color="secondary" fullWidth label="Select Brand ..." >
{allTransmissions.map((cat) => (
     <MenuItem key={cat} value={cat}>
     {cat}
    </MenuItem>
 ))}
</Select>
</Box>

            <Box sx={{width:"30%"}}>

            <InputLabel color="secondary" id="demo-simple-select-label414">Body Type</InputLabel>

<Select sx={{
 width:"100%"
}} variant="filled"  value={bodyType} onChange={(e)=>setBodyType(e.target.value)}   labelId="demo-simple-select-label414"  color="secondary" fullWidth label="Select Brand ..." >
   {allBodyTypes.map((cat) => (
                 <MenuItem key={cat} value={cat}>
                 {cat}
                </MenuItem>
             ))}
   </Select>
            </Box>
            <Box sx={{width:"30%"}}>

            <InputLabel color="secondary" id="demo-simple-select-label414">Fuel Type</InputLabel>

<Select sx={{
 width:"100%"
}} variant="filled"  value={fuel} onChange={(e)=>setFuel(e.target.value)}   labelId="demo-simple-select-label414"  color="secondary" fullWidth label="Select Brand ..." >
   {allFuels.map((cat) => (
                 <MenuItem key={cat} value={cat}>
                  {cat}
                 </MenuItem>
             ))}
   </Select>
            </Box>

          </Box>
          <Box sx={{
            display:"flex",
            justifyContent:"space-around",
            mt:3
          }}>

              <TextField 
                    sx={{
                        mt:3,
                        width:"30%"
                    }}
                    variant="filled"
                    type="number"
                    fullWidth
                    value={luggage}
                    onChange={(e)=>setLuggage(e.target.value)}
                    label="Luggage ..."
                    color="secondary"
                    />
                     <TextField 
                    sx={{
                        mt:3,
                        width:"30%"
                    }}
                    variant="filled"
                    type="number"
                    fullWidth
                    value={doors}
                    onChange={(e)=>setDoors(e.target.value)}
                    label="Doors ..."
                    color="secondary"
                    />
                     <TextField 
                    sx={{
                        mt:3,
                        width:"30%"
                    }}
                    variant="filled"
                    type="number"
                    fullWidth
                    value={passengers}
                    onChange={(e)=>setPassengers(e.target.value)}
                    label="Passengers ..."
                    color="secondary"
                    />

            </Box>

            <Box sx={{
            display:"flex",
            justifyContent:"space-around",
            mt:3
          }}>

              <Box sx={{mt:2}}>
                  <Switch checked={airConditioning} color="secondary" onChange={(e)=>setAirConditioning(e.target.checked)}   />
                  Air Conditioning
                </Box>
                <Box sx={{mt:2}}>
                  <Switch checked={stereo} color="secondary" onChange={(e)=>setStereo(e.target.checked)}   />
                  Stereo
                </Box>
                <Box sx={{mt:2}}>
                  <Switch checked={powerSteering} color="secondary" onChange={(e)=>setPowerSteering(e.target.checked)}   />
                  Power Steering
                </Box>
                <Box sx={{mt:2}}>
                  <Switch checked={HDTV} color="secondary" onChange={(e)=>setHDTV(e.target.checked)}   />
                  HDTV
                </Box>
            </Box>
            <Box sx={{
            display:"flex",
            justifyContent:"space-around",
            mt:3
          }}>

              <Box sx={{mt:2}}>
                  <Switch checked={powerWindows} color="secondary" onChange={(e)=>setPowerWindows(e.target.checked)}   />
                    Power Windows
                </Box>
                <Box sx={{mt:2}}>
                  <Switch checked={remoteKeyLessEntry} color="secondary" onChange={(e)=>setRemoteKeyLessEntry(e.target.checked)}   />
                  Remote Key Less Entry
                </Box>
                <Box sx={{mt:2}}>
                  <Switch checked={backUpCam} color="secondary" onChange={(e)=>setBackUpCam(e.target.checked)}   />
                  BackUp Camera
                </Box>
                <Box sx={{mt:2}}>
                  <Switch checked={DVD} color="secondary" onChange={(e)=>setDVD(e.target.checked)}   />
                  DVD
                </Box>
            </Box>
            <Box sx={{
            display:"flex",
            justifyContent:"space-around",
            mt:3
          }}>
 <TextField 
                    sx={{
                        mt:3,
                    }}
                    variant="filled"
                    type="text"
                    fullWidth
                    value={interiorMaterial}
                    multiline
                    rows="4"
                    onChange={(e)=>setInteriorMaterial(e.target.value)}
                    label="Interior Material ..."
                    color="secondary"
                    />
            </Box>
          
        </Box>
      
        
    
            
              
        </Box>
        <Box sx={{
            px:'5px'
        }}>
        <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">{NewVehicle? `Add New Vehicle` : `Edit Vehicle`}</Button>

        </Box>
          
        </form>
      )}
      </Formik>
    
    </Box>
  );
};
export default AddNewVehicle;