import { colorModeContext,useMo, useMode } from "./theme"
import { CssBaseline,ThemeProvider } from "@mui/material"
import Main from "./scenes/Pages/Main"
import SignIn from "./scenes/Pages/SignIn"
import { useState,useEffect } from "react"
import { getAuthentication } from "./auth"


function App() {
  const [theme,colorMode] = useMode()
  const [signedIn,setSignedIn] = useState(false)
  const [token,setToken] = useState('')
  useEffect(() => {
    if(getAuthentication() !== null){
      setSignedIn(true)
      setToken(getAuthentication())
    }
    
  }, [])

  const handleSignInStatus = () =>{
    setSignedIn(prev=> !prev)
    setToken(getAuthentication())

  }

  return (
    <colorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <div className="app">
            {
              signedIn? <Main signOutHandler={handleSignInStatus} /> : <SignIn signInHandler={handleSignInStatus} />
            }
          </div>
      </ThemeProvider>
      
    </colorModeContext.Provider>
    
  );
}

export default App;
