import { Box,useTheme,TableContainer,Table,TableBody,TableHead,TableCell,TableRow,TablePagination,Button,IconButton,Modal} from "@mui/material"
import Header from "../../../components/Header"
import { useState,useEffect } from "react"
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import axios from "axios";

import { tokens } from "../../../theme";

import { getAuthentication } from "../../../auth";


import AddNewFAQ from "./AddNewFAQ"

const API_URL = `https://dashapi.mivanrental.com/`


const FAQGQLSchema = `
id
Question
Answer
Rank
`

const FAQ = () =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [modalOpen,setModalOpen] = useState(false)
    const [modalStatus,setModalStatus] = useState('ADD')
    const [isNewItem,setIsNewItem] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    
    const [faqs,setFaqs] = useState([])
    const [selectedFaq,setSelectedFaq] = useState(null)

    const HandleMainInfoSubmit = (question,answer,rank) =>{
        isNewItem ? AddNewFaqFunc(question,answer,rank) : EditFaqFunc(question,answer,rank)
    }

   
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    



   
    const ModalComponents = {
        "ADD": <AddNewFAQ NewItem={true} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>,
        "EDIT": <AddNewFAQ NewItem={false} Item={selectedFaq} HandleMainInfoSubmit={HandleMainInfoSubmit} closingModal={handleCloseModal}/>     
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0)
    }

    const handleNewBtnClick = () =>{
        setIsNewItem(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleEditClick = (item) =>{
        setSelectedFaq(item)
        setModalStatus("EDIT")
        setIsNewItem(false)
        setModalOpen(true)
    }
    

  
    
    const handleDeleteClick = async(item) =>{
        try {
           await axios.post(API_URL, {
            query: `
              mutation {
                DeleteFAQ(id:"${item.id}") 
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
  
          fetchData()
  
        } catch (error) {
        }
    }

 
   
    
    const fetchData = async () => {
        setLoaderVisibility('flex')
        try{
          const res = await axios.post(API_URL,{
            query:`query{
                GetAllFAQ{
                ${FAQGQLSchema}
              }
            }`
        },{
          headers:{
            token:`Bearer ${getAuthentication()}`
          }
        })
        if(res.status === 200){
            setFaqs(res.data.data.GetAllFAQ)
          setLoaderVisibility('none')
        }
      }
      catch(err){}
    }


  

    const AddNewFaqFunc = async(question,answer,rank) =>{
        const res = await axios.post(API_URL, {
            query: `
              mutation {
                AddNewFAQ(question:"${question}",answer:"${answer}",rank:${rank}) {
                  ${FAQGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
          });
    
          if(res.data.data.AddNewFAQ.id !== null){
            fetchData()
          }
    }


   

    const EditFaqFunc = async(question,answer,rank) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditFAQ(id:"${selectedFaq.id}",question:"${question}",answer:"${answer}",rank:${rank}){
                        ${FAQGQLSchema}
                    }
                }
            `
        },{
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditFAQ.id !== null){
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        }, [page,rowsPerPage]);
    return(
        <Box sx={{
            m:'20px'
        }}>
            <Header title={`Frequently Asked Question`} subtitle={`Add, modify and delete FAQ`} />
            <Modal open={modalOpen}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                  <>
                    {ModalComponents[modalStatus]}
                  </>
            </Modal>

            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>
                  
                  
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Item</Button>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                justifyContent:"center",
                alignContent:"center",
                alignItems:"center",
            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
            </Box>

            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
<TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>Question</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
    {
    faqs.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map(lng => (
        <TableRow key={lng.id}>
        <TableCell>{lng.Question}</TableCell>
        <TableCell>
            <IconButton color="secondary" onClick={() => handleEditClick(lng)}> 
                <EditIcon />
            </IconButton>
           
            <IconButton color="error" onClick={() => handleDeleteClick(lng)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
      </TableRow>
  
))

    }
    </TableBody>
  </Table>
</TableContainer>   
<TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={faqs.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>         
        </Box>
        </Box>
    )
}

export default FAQ