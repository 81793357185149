import { useState,useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme,Divider} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ImageIcon from '@mui/icons-material/Image';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QuizIcon from '@mui/icons-material/Quiz';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { AirplaneTicket, BusAlert, MenuOutlined } from "@mui/icons-material";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");


  return (
    <Box
      sx={{
        position:"sticky",
        top:"0",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                Mivanrental
                </Typography>
              
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlined />
                </IconButton>
              </Box>
            )}

{!isCollapsed && (
     <Box
     display="flex"
     justifyContent="space-between"
     alignItems="center"
     ml="15px"
   >
    <Typography variant="h6" color="secondary">
    Powered by ULDASH
  </Typography>
  </Box>

)}
            
          </MenuItem>

         

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

            <Item
              title="Static Text"
              to="/statictext"
              icon={<TextFieldsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Carousel"
              to="/carousel"
              icon={<ImageIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Why Choose Us"
              to="/chooseus"
              icon={<FormatListBulletedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="FAQ"
              to="/faq"
              icon={<QuizIcon />}
              selected={selected}
              setSelected={setSelected}
              />

            <Divider />

            <Item
              title="Vehicles"
              to="/vehicles"
              icon={<DirectionsCarIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Brands"
              to="/brands"
              icon={<BrandingWatermarkIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider />
            <Item
              title="Requests"
              to="/requests"
              icon={<RequestPageIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Inbox"
              to="/inbox"
              icon={<AllInboxIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Airport Pickup"
              to="/airPortPickUp"
              icon={<AirplaneTicket />}
              selected={selected}
              setSelected={setSelected}
            />
               <Item
              title="Shuttle Bus"
              to="/shuttleBus"
              icon={<BusAlert />}
              selected={selected}
              setSelected={setSelected}
            />
           
             

         
           
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;